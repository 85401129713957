import { Component, OnInit } from '@angular/core';

declare function openW(wID);

@Component({
  selector: 'app-es-footer',
  templateUrl: './es-footer.component.html',
  styleUrls: ['./es-footer.component.css']
})
export class EsFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onOpenW(event, wID){
    event.preventDefault();
    openW(wID);
  }

}
