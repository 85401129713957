import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.css']
})
export class DonateComponent implements OnInit {

  vmDevice = 'desktop';

  constructor() { }

  evalWin(){
    var W = window.innerWidth;
    this.vmDevice = W < 700 ? 'mobile' : 'desktop'
  }

  ngOnInit() {
    this.evalWin()
  }

}
