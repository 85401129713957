import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { CountUpModule } from 'countup.js-angular2';
import { MapMxComponent } from './en/map-mx/map-mx.component';
import { NumbersComponent } from './en/numbers/numbers.component';
import { FooterComponent } from './en/footer/footer.component';
import { ProcessComponent } from './en/process/process.component';
import { DonateComponent } from './en/donate/donate.component';
import { NavComponent } from './en/nav/nav.component';
import { IseenowComponent } from './en/iseenow/iseenow.component';
import { EsIseenowComponent } from './es/es-iseenow/es-iseenow.component';
import { EsNavComponent } from './es/es-nav/es-nav.component';
import { EsProcessComponent } from './es/es-process/es-process.component';
import { NumerosComponent } from './es/numeros/numeros.component';
import { MapaComponent } from './es/mapa/mapa.component';
import { EsFooterComponent } from './es/es-footer/es-footer.component';
import { WRealchangesComponent } from './es/w-realchanges/w-realchanges.component';
import { WEnRealchangesComponent } from './en/w-en-realchanges/w-en-realchanges.component';
import { DonationFormComponent } from './en/donation-form/donation-form.component';
import { EsDonationFormComponent } from './es/es-donation-form/es-donation-form.component';
import { TermsComponent } from './en/terms/terms.component';
import { EsTermsComponent } from './es/es-terms/es-terms.component';
import { EsDonateComponent } from './es/es-donate/es-donate.component';
import { DonationFormMobComponent } from './en/donation-form-mob/donation-form-mob.component';
import { EsDonationFormMobComponent } from './es/es-donation-form-mob/es-donation-form-mob.component';

@NgModule({
  declarations: [
    AppComponent,
    MapMxComponent, 
    NumbersComponent,
    FooterComponent,
    ProcessComponent,
    DonateComponent,
    NavComponent,
    IseenowComponent,
    EsIseenowComponent,
    EsNavComponent,
    EsProcessComponent,
    NumerosComponent,
    MapaComponent,
    EsFooterComponent,
    WRealchangesComponent,
    WEnRealchangesComponent,
    DonationFormComponent,
    EsDonationFormComponent,
    TermsComponent,
    EsTermsComponent,
    EsDonateComponent,
    DonationFormMobComponent,
    EsDonationFormMobComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CountUpModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
