import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-es-process',
  templateUrl: './es-process.component.html',
  styleUrls: ['./es-process.component.css']
})
export class EsProcessComponent implements OnInit {

  pHandler: any;
  step = 1;

  constructor() { }

  ngOnInit() {
  }

  onPrevStep(){
    this.step--;
    if (this.step < 0 ) { this.step = 2 };
    document.getElementById('processes').style.left = '-'+ ( this.step * 100 ) + '%';
  }

  onNextStep(){
    this.step++;
    if (this.step > 2 ) { this.step = 0 };
    document.getElementById('processes').style.left = '-'+ ( this.step * 100 ) + '%';
  }

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000
        && Math.abs(direction[0]) > 30 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        //
        switch (swipe){
          case 'next':
            if ( this.step < 2){ this.step++; }
            break;
          case 'previous':
              if ( this.step > 0){ this.step--; }
              break;
        }
        console.log(this.step)
        document.getElementById('processes').style.left = ( '-'+ this.step * 100 )+'%';
      }
    }
  }
  
}
