import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-donation-form-mob',
  templateUrl: './donation-form-mob.component.html',
  styleUrls: ['./donation-form-mob.component.css']
})
export class DonationFormMobComponent implements OnInit {

  amount = 15;
  valX = 0;
  maxVal = 2;

  constructor(
    private _router: Router
  ) { }

  ngOnInit() {
    this.onChange(event)
  }

  toggleActive(){
    var actS = document.getElementsByClassName('toggleButton');
    for ( var i = 0; i < actS.length; i++){
      actS[i].classList.toggle('active');
    }
  }

  onNext(){
    if ( this.valX < this.maxVal ){
      this.valX++;
      console.log(this.valX);
      var bPrev = document.getElementById('btn-prev') as HTMLButtonElement;
      bPrev.disabled = false;
      //
      if ( this.valX == this.maxVal ){ this.toggleActive(); }
    }
  }
  onPrev(){
    if ( this.valX > 0 ){
      this.valX--;
      console.log(this.valX);
    }
    var bPrev = document.getElementById('btn-prev') as HTMLButtonElement;
    if (this.valX == 0){
      bPrev.disabled = true;
    }
    document.getElementsByClassName('toggleButton')[0].classList.add('active');
    document.getElementsByClassName('toggleButton')[1].classList.remove('active');
  }

  onChange(event){
    var chbxFact = document.getElementById('chbFact') as HTMLInputElement;
    if (chbxFact.checked){
      console.log('yes');
      this.maxVal = 3;
    } else {
      console.log('no');
      this.maxVal = 2;
    }
  }

  onChangeAddress(event){
    var chbF = event.target as HTMLInputElement;
    var adrsD = document.getElementById('address2') as HTMLDivElement;
    if (chbF.checked == true){
      adrsD.classList.remove('active');
    } else {
      adrsD.classList.add('active');
    }
  }

  onGoTo(){
    this._router.navigate(['/donar']);
  }

  onAnother(){
    this.amount =0;
    document.getElementById('anotherQty').focus();
  }

}
