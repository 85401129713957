import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-es-donate',
  templateUrl: './es-donate.component.html',
  styleUrls: ['./es-donate.component.css']
})
export class EsDonateComponent implements OnInit {

  vmDevice = 'desktop';
  
  constructor() { }

  evalWin(){
    var W = window.innerWidth;
    this.vmDevice = W < 700 ? 'mobile' : 'desktop'
  }

  ngOnInit() {
    this.evalWin()
  }

}
