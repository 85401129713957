import { Component, OnInit } from '@angular/core';

declare function setLoc( place1:any, place2:any, qty:any );

@Component({
  selector: 'app-map-mx',
  templateUrl: './map-mx.component.html',
  styleUrls: ['./map-mx.component.css']
})
export class MapMxComponent implements OnInit {

  mapA = 'MX';
  locE = 'Quintana Roo';

  locE2 = 'Quintana Roo';

  constructor() { }

  ngOnInit() {
  }

  onSetLoc( place1, place2, qty ){
    setLoc( place1, place2, qty );
    this.locE = place1;
  }
  onSetLoc2( place1, place2, qty ){
    setLoc( place1, place2, qty );
    this.locE2 = place1;
  }

  onSelMap(Map){
    this.mapA = Map;
    var mapE = document.getElementById('map') as HTMLDivElement;
    //
    if( this.mapA == 'MX' ){
      mapE.classList.remove('pos2');
      setLoc( 'Quintana Roo', 'Mexico', '1,280+' );
      this.locE = 'Quintana Roo';
    } else {
      mapE.classList.add('pos2');
      setLoc( 'Kenya', 'Nairobi', '1,060+' );
      this.locE = 'Kenya';
    }
  }

}
