import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-es-nav',
  templateUrl: './es-nav.component.html',
  styleUrls: ['./es-nav.component.css']
})
export class EsNavComponent implements OnInit {

  constructor(
    private _router: Router
  ) { }

  ngOnInit() {
  }

  onGoTo(){
    this._router.navigate(['/iseenow']);
  }

}
