import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-numeros',
  templateUrl: './numeros.component.html',
  styleUrls: ['./numeros.component.css']
})
export class NumerosComponent implements OnInit {

  fHandler: any;
  topN: any;

  initCount = false;

  endVal1;
  endVal2;
  endVal3;
  endVal4;

  pass = 0;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.topN = document.getElementById('scrll').offsetHeight - document.getElementById('main-container').offsetHeight + document.getElementById('numbers').offsetHeight;
    this.fHandler = this.onScroll.bind(this);
    document.querySelector('#main-container').addEventListener('scroll', this.fHandler, true);
  }

  onScroll(){
    var mcST = document.getElementById('main-container').scrollTop;
    if ( mcST > this.topN && this.initCount == false ){
      console.log('PUNTO');
      this.endVal1 = '19360';
      this.endVal2 = '6345';
      this.endVal3 = '5';
      this.endVal4 = '1';
      this.initCount = true;
      //document.querySelector('#main-container').removeEventListener('scroll', this.fHandler, true);
    }
    //
    var wW = window.innerWidth;
    var wH = window.innerHeight;
    var bnnrE = document.getElementsByClassName('banner')[0] as HTMLDivElement;
    var vidE = document.getElementById('video') as HTMLDivElement;
    var tH = bnnrE.offsetHeight + vidE.offsetHeight;
    var prsT = document.getElementById('process') as HTMLDivElement;
    var psT = prsT.offsetHeight;
    //
    //var p1 = wH / 3;
    var p2 = wH / 3 * 2

    if( wW < 700 ){
      var mcSR = wH - mcST;

      if(mcSR < p2 && this.pass==0 ) {
        document.getElementById('processes').style.left = '-100%';
        this.pass = 1;
      }
    }
      /*
      if( mcSR <= p1 ){
        document.getElementById('processes').style.left = '-200%';
      } else if( mcSR > p1 && mcSR < p2 ) {
        document.getElementById('processes').style.left = '-100%';
      } else if( mcSR >= p2 ){
        document.getElementById('processes').style.left = '0%';
      }
*/
    console.log(mcST);
    //console.log(p1);
    console.log(p2);
  }

}

