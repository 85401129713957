import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-w-en-realchanges',
  templateUrl: './w-en-realchanges.component.html',
  styleUrls: ['./w-en-realchanges.component.css']
})
export class WEnRealchangesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
