import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import { IseenowComponent } from './en/iseenow/iseenow.component';
import { DonationFormComponent } from './en/donation-form/donation-form.component';
import { DonateComponent } from './en/donate/donate.component';

import { EsIseenowComponent } from './es/es-iseenow/es-iseenow.component';
import { EsDonationFormComponent } from './es/es-donation-form/es-donation-form.component';
import { EsDonateComponent } from './es/es-donate/es-donate.component';

const routes: Routes = [
  { path: '', redirectTo:'iseenow', pathMatch: 'full' },
  
  { path: 'iseenow', component: IseenowComponent },
  { path: 'donate', component: DonateComponent },
  { path: 'es_iseenow', component: EsIseenowComponent },
  { path: 'donar', component: EsDonateComponent }
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
      RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
